import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import whiteNimatronLogo from '../../assets/logo-blanco.png';

import './Navbar.css';

const Navbar = () => {

    const [scrollPosition, setScrollPosition] = React.useState(window.scrollY);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);

    const handleScroll = () => {
        if (scrollPosition > 20) {
            return 'navbar navbar__fixed';
        } else {
            return 'navbar';
        }
    }

    return (
        <div className={handleScroll()}>
            <a className="nav__logo" href='/#'>
                <img src={whiteNimatronLogo} alt="logo" />
            </a>
            <div className="nav__links">
                <a className="nav__link" href='#services'>
                    <FormattedMessage id={"services"} />
                </a>
                <a className="nav__link" href='#about-us'>
                    <FormattedMessage id={"about_us"} />
                </a>
                <a className="nav__link" href='#contact-us'>
                    <FormattedMessage id={"contact"} />
                </a>
            </div>
            <div className='nav__languages'>
                <div className="nav__language" >
                    <a href='/es'>ES</a>
                </div>
                <div className="nav__language" >
                    <a href='/en'>EN</a>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(Navbar);