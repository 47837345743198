import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";
import "./Dashboard.css";

const Dashboard = ({ children }) => {
    return (
        <div className="dashboard">
            <Navbar />
            {children}
            <Outlet />
            <Footer />
        </div>
    );
};

export default Dashboard;
