import React, { useState } from "react";
import "./Home.css";

import { BsCheck2Circle } from 'react-icons/bs';
import PexelsFauxels from "../../assets/pexels-fauxels.jpg";
import { injectIntl, FormattedMessage } from 'react-intl';
import data from "../../locales/services.json";

const Home = ({language}) => {
    const [mailNombre, setMailNombre] = useState("");
    const [mailMensaje, setMailMensaje] = useState("");

    
    const services = data[language];

    return (
        <div className="Home">
            <div className="Home__header">
                <div className="Home__header__text">
                    <h1><FormattedMessage id={"home_header_title"} /></h1>
                    <p><FormattedMessage id={"home_header_subtitle"} /></p>
                </div>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#3a3939" fillOpacity="1" d="M0,192L48,165.3C96,139,192,85,288,80C384,75,480,117,576,160C672,203,768,245,864,224C960,203,1056,117,1152,101.3C1248,85,1344,139,1392,165.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>

            <div className="Home__content" id="services">
                <div className="Home__content__body body__space">
                    <h1 className="border-bottom"><FormattedMessage id={"services"} /></h1>
                    <div className="Home__content__body__services">
                        {
                            services.map((service, index) => (
                                <div className="services__card" key={index}>
                                    <div className="services__card__header">
                                        <h3>{service.name}</h3>
                                    </div>
                                    <div className="services__card__body">
                                        {service.description}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="Home__content__body__banner">
                    <h1><FormattedMessage id={"developing_technology"} /></h1>
                    <p><FormattedMessage id={"specialized_in"} /></p>
                </div>

                <div className="aboutUs__section body__space" id="about-us">
                    <div className="aboutUs__content">
                        <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
                            <defs>
                                <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                    <stop id="stop1" stopColor="rgba(248, 117, 55, 1)" offset="0%"></stop>
                                    <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <path fill="url(#sw-gradient)" d="M17.5,-24.3C23.9,-23,31.4,-20.4,32.8,-15.5C34.3,-10.6,29.7,-3.5,28.6,4.1C27.5,11.8,30,19.9,26.7,22.2C23.3,24.5,14.2,20.9,7.2,21.8C0.1,22.7,-4.8,28,-9.4,28.4C-14,28.7,-18.1,24,-24.2,19.5C-30.2,15.1,-38,10.8,-41.5,4.3C-45,-2.2,-44.1,-11,-39.7,-17C-35.2,-23.1,-27.1,-26.4,-19.9,-27.3C-12.7,-28.3,-6.4,-26.9,-0.4,-26.2C5.5,-25.5,11,-25.6,17.5,-24.3Z" width="100" height="100%" transform="translate(50 50)" strokeWidth="0" ></path>
                        </svg>
                        <div className="aboutUs__text">
                            <div className="aboutUs__title">
                                <h1> <FormattedMessage id={"about_us"} /></h1>
                            </div>
                            <p><FormattedMessage id={"about_us_description"} /></p>
                        </div>
                        <div className="aboutUs__image">
                            <img src={PexelsFauxels} alt="Foto de fauxels: https://www.pexels.com/es-es/foto/foto-de-vista-superior-de-un-grupo-de-personas-que-usan-macbook-mientras-discuten-3182773/" />
                        </div>
                    </div>
                </div>

                <div className="Home__content__body body__space" id="contact-us">

                    <h1><FormattedMessage id={"talk_about_project"} /></h1>
                    <p><FormattedMessage id={"contact_us"} /></p>

                    <div className="Home__content__body__form" id="contact">
                        <div className="form-group">
                            <FormattedMessage id="input_name_placeholder">
                                {placeholder => (
                                    <input className="form-control" name="from_name" type="text" id="nombre" placeholder={placeholder} onChange={(e) => { setMailNombre(e.target.value) }} />
                                )}
                            </FormattedMessage>
                        </div>
                        <div className="form-group">
                            <FormattedMessage id="input_message_placeholder">
                                {placeholder => (
                                    <input className="form-control" name="message" type="text" placeholder={placeholder} onChange={(e) => { setMailMensaje(e.target.value) }} />
                                )}
                            </FormattedMessage>
                        </div>
                        <div className="form-group contact-button">
                            <a href={`mailto:alex.alzate95@gmail.com?subject=Quiero contactar contigo, mi nombre es ${mailNombre}&body=${mailMensaje}`} className="form-button"><FormattedMessage id="send_button"/></a>
                        </div>
                    </div>
                </div>

            </div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ea5e29" fillOpacity="1" d="M0,192L48,202.7C96,213,192,235,288,218.7C384,203,480,149,576,128C672,107,768,117,864,138.7C960,160,1056,192,1152,186.7C1248,181,1344,139,1392,117.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>

            <div className="Home__banner">
                <h1><FormattedMessage id="banner_title"/></h1>
                <div className="Home__banner__options">
                    <p><BsCheck2Circle /><FormattedMessage id="banner_flexibility"/></p>
                    <p><BsCheck2Circle /><FormattedMessage id="banner_quality"/></p>
                    <p><BsCheck2Circle /><FormattedMessage id="banner_trust"/></p>
                </div>
            </div>
        </div>
    );
};

export default injectIntl(Home);
