import React from 'react';
import './NotFound.css';

const NotFound = () => {
    return ( 
        <div className="notfound">
            <h1>404 - Not Found!</h1>
        </div>
     );
}
 
export default NotFound;