import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "../layout/Dashboard";
import Home from '../views/home/Home';
import NotFound from "../views/notFound/NotFound";


const RedirectToLanguage = ({ defaultLanguage }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${defaultLanguage}`, { replace: true });
  }, [defaultLanguage, navigate]);

  return null; 
};

const CreateRoutes = ({ language }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectToLanguage defaultLanguage={language} />} />
        <Route path="/:lang" element={<Dashboard />}>
          <Route index element={<Home language={language}/>} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default CreateRoutes;
