import './App.css';
import CreateRoutes from './routes/Routes';
import { IntlProvider } from 'react-intl';
import localeData from './locales/data.json';

function App() {
  const getLanguageFromUrl = () => {
    const path = window.location.pathname;
    const language = path.split('/')[1]; 
    return language || 'es'; 
  };
  const language = getLanguageFromUrl();
  const messages = localeData[language] || localeData['es']; 

  return (
    <IntlProvider locale={language} messages={messages}>
      <CreateRoutes language={language}/>
    </IntlProvider>
  );
}

export default App;
