import React from 'react';
import { PiInstagramLogoFill, PiFacebookLogoFill, PiLinkedinLogoFill } from 'react-icons/pi';
import whiteNimatronLogo from '../../assets/logo-blanco.png';
import './Footer.css';

const Footer = () => {
    return (
        <div className="Home__footer">
            <img
                className="Home__footer__logo"
                src={whiteNimatronLogo}
                alt="logo"
            />
            <div className="Home__footer__projects bold">
                <a href="https://noiseless-tuesday-a24.notion.site/Scholar-e35535f265e44b97a206239c37db17e1?pvs=4" target="_blank" rel="noreferrer">Scholar</a>
                <a href="https://statup.nimatron.net/" target="_blank" rel="noreferrer">StatUp</a>
            </div>

            <div className="Home__footer__separator"></div>
            <div className="Home__footer__bottom">
                <p>© 2020 Nimatron. All rights reserved</p>
                <div className="Home__footer__bottom__social">
                    <a href="#"><PiInstagramLogoFill /></a>
                    <a href="#"><PiFacebookLogoFill /></a>
                    <a href="#"><PiLinkedinLogoFill /></a>
                </div>
            </div>

        </div>
    );
}

export default Footer;
